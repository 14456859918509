<template>
  <div class="main-box" style="position: relative;">
    <div class="mg-bt-10" style="position: fixed; top: 80px; left: 295px; z-index: 10;">
      <el-button type="primary" size="small" icon="el-icon-plus" @click="addMenu()">添加</el-button>
      <el-button type="danger" size="small" icon="el-icon-delete" @click="delMenu()">删除</el-button>
    </div>
    <el-table
            :data="tableData"
            ref="rfTable"
            border
            row-key="id"
            style="width: 100%; margin-top: 30px;"
            size="mini"
            default-expand-all
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
      <el-table-column
              type="selection"
              align="center"
              width="55">
      </el-table-column>
      <el-table-column
              prop="title"
              label="名称">
      </el-table-column>
      <el-table-column
              prop="authkey"
              label="模块键值">
      </el-table-column>
      <el-table-column
              prop="ordering"
              label="排序"
              align="center"
              width="120">
      </el-table-column>
      <el-table-column
              prop="ftime"
              label="添加时间"
              align="center"
              width="140">
      </el-table-column>
      <el-table-column
              fixed="right"
              label="操作"
              align="center"
              width="100">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="editMenu(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :title="menuTitle" :visible.sync="dialogFormVisible" custom-class="dialog" :close-on-click-modal="false">
      <el-form :model="form" size="small">
        <el-form-item label="菜单名称" :label-width="formLabelWidth">
          <el-input v-model="form.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="父级菜单" :label-width="formLabelWidth">
          <el-select v-model="form.pid" placeholder="请选择菜单">
            <el-option :label="item.title" :value="item.id" v-bind:key="index" v-for="(item, index) in tableData"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="模块键值" :label-width="formLabelWidth">
          <el-input v-model="form.authkey" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="排序" :label-width="formLabelWidth">
          <el-input v-model="form.ordering" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveMenu" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      info: false,
      source_domain: this.config.SOURCE_DOMAIN,
      menuTitle: '添加菜单',
      dialogFormVisible: false,
      form: {
        id: 0,
        pid: 0,
        title: '',
        authkey: '',
        ordering: ''
      },
      formLabelWidth: '120px',
      tableData: []
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    //
  },
  computed: {
  },
  methods: {
    getList() {
      var that = this
      that.showLoading()
      this.$api.merchant.menuIndex({}, function (res) {
        that.hideLoading()
        if(res.errcode == 0) {
          that.tableData = res.data
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    editMenu(item) {
      var that = this
      this.$api.merchant.menuDetail({id: item.id},function (res) {
        if(res.errcode == 0) {
          that.form = res.data
          if(that.form.pid == 0) {
            that.form.pid = ''
          }
          that.dialogFormVisible = true
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    addMenu() {
      this.form.id = 0
      this.form.pid = ''
      this.form.title = ''
      this.form.authkey = ''
      this.form.ordering = ''
      this.dialogFormVisible = true
    },
    saveMenu() {
      var that = this
      var param = {
        id: that.form.id,
        pid: that.form.pid,
        title: that.form.title,
        authkey: that.form.authkey,
        ordering: that.form.ordering
      }
      if(this.form.id == 0) {
        this.$api.merchant.menuAdd(param,function (res) {
          if(res.errcode == 0) {
            that.dialogFormVisible = false
            that.getList()
            that.success(res.errmsg)
          } else {
            that.fail(res.errmsg)
          }
        })
      } else {
        this.$api.merchant.menuEdit(param,function (res) {
          if(res.errcode == 0) {
            that.dialogFormVisible = false
            that.getList()
            that.success(res.errmsg)
          } else {
            that.fail(res.errmsg)
          }
        })
      }
    },
    delMenu() {
      var that = this
      var sel = this.$refs.rfTable.selection
      if(sel.length<1) {
        that.tips('请选择删除项')
        return false
      }
      var ids = []
      sel.forEach(function (item) {
        ids.push(item.id)
      })
      this.$confirm('确认要删除这些数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.merchant.menuDelete({ids: ids},function (res) {
          if(res.errcode == 0) {
            that.success('删除成功')
            that.getList()
          } else {
            that.fail(res.errmsg)
          }
        })
      }).catch(() => {

      });
    }
  }
};
</script>
<style scoped>
</style>
